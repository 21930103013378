<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <h3 class="mb-1">
          Register to Start Playing
        </h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card class="mb-1">
          <validation-observer
            ref="registerForm"
            #default="{ invalid, dirty }"
          >
            <b-form
              class="form-container"
              @submit.prevent="register"
            >
              <b-row class="mt-1">
                <b-col md="12">
                  <b-form-group
                    label="First Name"
                    label-for="firstName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      rules="required"
                      vid="firstName"
                    >
                      <b-form-input
                        id="firstName"
                        v-model.trim="firstName"
                        type="text"
                        class="form-control"
                        placeholder="First Name"
                        trim
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="Last Name"
                    label-for="lastName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      rules="required"
                      vid="lastName"
                    >
                      <b-form-input
                        id="lastName"
                        v-model.trim="lastName"
                        type="text"
                        class="form-control"
                        placeholder="Last Name"
                        trim
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                      vid="email"
                    >
                      <b-form-input
                        id="email"
                        v-model.trim="userEmail"
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        autocomplete="username"
                        trim
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="Mobile Phone"
                    label-for="phone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Mobile Phone"
                      rules="required"
                      vid="phone"
                    >
                      <VuePhoneNumberInput
                        id="phone"
                        v-model.trim="phoneNumberInput"
                        default-country-code="CA"
                        :preferred-countries="['CA', 'US']"
                        @update="handleFormatMobile"
                      />
                      <!--<b-form-input
                        id="phone"
                        v-model.trim="mobile"
                        type="text"
                        class="form-control"
                        placeholder="Phone"
                        trim
                      />-->
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="Date of Birth"
                    label-for="dateOfBirth"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Date of Birth"
                      rules="required"
                      vid="dateOfBirth"
                    >
                      <b-input-group class="mb-1">
                        <b-form-input
                          id="dateOfBirth"
                          v-model="dob"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="off"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="dob"
                            show-decade-nav
                            button-only
                            right
                            locale="en-US"
                            aria-controls="dateOfBirth"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="Password"
                    label-for="password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                      vid="password"
                    >
                      <b-form-input
                        id="password"
                        v-model.trim="password"
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        autocomplete="new-password"
                        trim
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="Confirm Password"
                    label-for="confirmPassword"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm Password"
                      rules="required"
                      vid="confirmPassword"
                    >
                      <b-form-input
                        id="confirmPassword"
                        v-model.trim="confirmPassword"
                        type="password"
                        class="form-control"
                        placeholder="Confirm Password"
                        autocomplete="new-password"
                        trim
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="12"
                  class="mt-1"
                >
                  <b-form-group>
                    <b-form-checkbox
                      id="termsAgree"
                      v-model="accept"
                      name="termsAgree"
                    >
                      I agree to the Terms &amp; Privacy Policy
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="form-group text-right"
                >
                  <b-button
                    variant="primary"
                    type="submit"
                    :disabled="invalid || !dirty"
                  >
                    Submit
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BButton,
  BForm, BFormGroup, BFormInput, BFormCheckbox, BFormDatepicker,
  BInputGroup, BInputGroupAppend, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { required, confirmed, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import IndexBackground from '@/assets/images/backgrounds/index.png'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BCard,

    // form validation
    ValidationProvider,
    ValidationObserver,
    // phone number
    VuePhoneNumberInput,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      userEmail: '',
      mobile: '',
      dob: '',
      password: '',
      confirmPassword: '',
      accept: false,
      phoneNumberInput: '', // DELETE?

      // form validation
      email,
      confirmed,
      required,
    }
  },
  created() {
    document.body.style.backgroundImage = `url(${IndexBackground})`
    document.body.style.backgroundSize = 'cover'
  },
  mounted() {
  },
  methods: {
    register() {
      /* register new user */
      useJwt.register({
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.userEmail,
        mobile: this.mobile,
        dob: this.dob,
        password: this.password,
        c_password: this.confirmPassword,
      })
        .then(response => {
          console.log(response)
          localStorage.setItem('canVerify', JSON.stringify(true))
          this.$router.replace('/verification')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Registration Complete',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Please verify your account. Your code has been sent via email.',
                },
              })
            })
        })
        .catch(error => {
          console.log(error.response)
          const errorMessage = error.response.data.error || 'This request did not go through.'

          // error message
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Registration Error',
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${errorMessage}`,
            },
          })
        })
    },

    /* HANDLERS */

    handleFormatMobile(payload) {
      this.mobile = payload.formattedNumber
    },
  },
}
</script>

<style>
</style>
