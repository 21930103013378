var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mb-1"},[_vm._v(" Register to Start Playing ")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{staticClass:"mb-1"},[_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"firstName"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required","vid":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"firstName","type":"text","placeholder":"First Name","trim":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"firstName"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"lastName"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required","vid":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"lastName","type":"text","placeholder":"Last Name","trim":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"lastName"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"email","type":"text","placeholder":"Email","autocomplete":"username","trim":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"userEmail"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Mobile Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Mobile Phone","rules":"required","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"id":"phone","default-country-code":"CA","preferred-countries":['CA', 'US']},on:{"update":_vm.handleFormatMobile},model:{value:(_vm.phoneNumberInput),callback:function ($$v) {_vm.phoneNumberInput=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phoneNumberInput"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Date of Birth","label-for":"dateOfBirth"}},[_c('validation-provider',{attrs:{"name":"Date of Birth","rules":"required","vid":"dateOfBirth"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mb-1"},[_c('b-form-input',{attrs:{"id":"dateOfBirth","type":"text","placeholder":"YYYY-MM-DD","autocomplete":"off"},model:{value:(_vm.dob),callback:function ($$v) {_vm.dob=$$v},expression:"dob"}}),_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"show-decade-nav":"","button-only":"","right":"","locale":"en-US","aria-controls":"dateOfBirth"},model:{value:(_vm.dob),callback:function ($$v) {_vm.dob=$$v},expression:"dob"}})],1)],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"password","type":"password","placeholder":"Password","autocomplete":"new-password","trim":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"confirmPassword"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required","vid":"confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"confirmPassword","type":"password","placeholder":"Confirm Password","autocomplete":"new-password","trim":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"confirmPassword"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"termsAgree","name":"termsAgree"},model:{value:(_vm.accept),callback:function ($$v) {_vm.accept=$$v},expression:"accept"}},[_vm._v(" I agree to the Terms & Privacy Policy ")])],1)],1),_c('b-col',{staticClass:"form-group text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":invalid || !dirty}},[_vm._v(" Submit ")])],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }